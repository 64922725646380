import React, { useState, Fragment, useEffect } from "react";

import HeaderLCAPage from "./HeaderLCAPage";

import Axios from "axios";

//backend integration: https://www.youtube.com/watch?v=3YrOOia3-mo

// Tables buttons and edits: https://www.youtube.com/watch?v=dYjdzpZv5yc


function AiAssistance({selectedProject}) {

   

    return (

    
       

     <div className="app-container">


        <HeaderLCAPage selectedProject={selectedProject}/>

        <div className="container">
        <iframe
  src="https://www.chatbase.co/chatbot-iframe/usyaO7-wRr4Z0g95Lyv7p"
  title="Chatbot"
  width="100%"
  style={{ height: "100%", minHeight: "700px" }}
  frameBorder="0"
></iframe>

           </div>
        
        </div>
    
    );
  
  }
        
  

export default AiAssistance;
