import React from "react";

function Help() {
  
  return (
    <div className="nonlcalabtab">
      <h1>Help Section</h1>
      <p>In order to advance with the software,please start by identifying......</p>
    </div>
  );
}

export default Help;
