import React, { useState, Fragment, useEffect } from "react";

import HeaderLCAPage from "./HeaderLCAPage";

import Axios from "axios";

//backend integration: https://www.youtube.com/watch?v=3YrOOia3-mo

// Tables buttons and edits: https://www.youtube.com/watch?v=dYjdzpZv5yc


function Notes({selectedProject,setSelectedProject}) {

  const [editMode, setEditMode] = useState(false); 

  const [editNotesData, setEditNotesData]=useState(selectedProject);


  const handleEditProjectClick = () =>{
    const mode=editMode;
    setEditMode(!mode);
    // let formValues=selectedProject;
    // formValues.projectNotes=selectedProject.projectNotes;
    //     setEditProjectData(formValues);
}

const handleEditNotesData = (event)=>{

  

 
  const fieldValue=event.target.value;
 console.log("FIELD VALUE IN EDITPROJECTDATA",fieldValue )
 const newFormData = { ...editNotesData, projectNotes: fieldValue };
  console.log("NEW FORM DATA IN EDITPROJECTDATA",newFormData )
  setEditNotesData(newFormData);
  

  //setSelectedProject(newFormData);
  
}

const handleSaveEditProject=()=>{

  const mode=editMode;
  setEditMode(!mode);

  setSelectedProject(editNotesData);


  //Code to send the new project, including new notes data to DB backend

  const id=editNotesData.id;
  const projectName=editNotesData.projectName;
  const projectUserID=editNotesData.projectUserID;
  const description=editNotesData.description;
  const numberofalternatives=editNotesData.numberofalternatives;
  const midpoints=editNotesData.midpoints;
  const projectNotes=editNotesData.projectNotes;

  Axios.post("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/editproject", {id:id, projectName:projectName, projectUserID: projectUserID, description:description, numberofalternatives:numberofalternatives,midpoints:midpoints,projectNotes: projectNotes}).then(()=>alert("success"));




};

const handleCancelEditClick=()=>{
  const mode=editMode;
     setEditMode(!mode);
     setEditNotesData(selectedProject);
 };
   
    useEffect(()=>{
  
        console.log("SELECTED PROJECT:");
        console.log(selectedProject);


      },[]);

   

    return (
    
       

     <div className="app-container">


        <HeaderLCAPage selectedProject={selectedProject}/>

        <div className="container">
        <div><h2> {selectedProject.projectName}</h2></div>

        <div  style={{ display: 'flex', direction: 'row',alignItems: 'center'}}><h1 style={{ padding: '10px'}}> Notes</h1>  {!editMode?(<div><button onClick={() => handleEditProjectClick()}>{selectedProject.projectNotes?("Edit Notes"):"Add Notes"}</button></div>):<div><button onClick={() => handleSaveEditProject()}>Save</button><button onClick={() => handleCancelEditClick()}>Cancel</button></div>}</div> 

        {!editMode?( <p> { selectedProject.projectNotes?( //Only do the conversion work (in order to allow paragraphing) if contact.comments in not null, otherwise we get an error
    <span dangerouslySetInnerHTML={{ __html: selectedProject.projectNotes.replace(/\n/g, '<br>') }} />
  ) : null} </p>): 
        <textarea
        //type="textarea"
        style={{ width: '100%', height: '70vh', padding: '10px'}}

        value={editNotesData.projectNotes || '   ' } //add || '' to Ensure the value is defined; if undefined, use an empty string type="text"
       
        maxLength="10000"
        name="notes"
        required="required"
        placeholder="Use this field to take notes on your project, by documenting your funtional unit, a description of the project, the hypothesis you have used and even the results interpretation"
        onChange={handleEditNotesData}
        />
        
        
        }
        
        </div> 
        </div>
    
    );
  
  }
        
  

export default Notes;
