import React, { useImperativeHandle } from "react";
import { Link, useMatch, useResolvedPath} from "react-router-dom";

function HeaderLCAPage({selectedProject}) {


// Function customLInk to see if our path matches a specific path, in order for us to style the button that is active differently, we use props to communicatebetween those
  function CustomLink({to, children, ...props}){
    const resolvedPath=useResolvedPath(to);
    const isActive=useMatch({path:resolvedPath.pathname, end: true})
    return (
      <li className={isActive?"active":""}>
      <Link to={to}{...props}>{children}</Link>
      </li>

    )

  }

  return (
    <nav className="verticalnav">
      
      <ul className="verticalnavUL"> 

      <CustomLink to="/">Project Selection</CustomLink>
      {/* Only show inputs and outputs if we have selected a project */}
      {selectedProject? <CustomLink to="/lcapage/inputs">Inputs</CustomLink>:(null)} 
      {selectedProject? <CustomLink to="/lcapage/outputs">Outputs</CustomLink>:(null)}
      {selectedProject? <CustomLink to="/lcapage/notes">Notes</CustomLink>:(null)}
      <CustomLink to="/lcapage/aiassistance">AI Assistance</CustomLink>
      {/*<CustomLink to="/lcapage/impactfactors">Search Impact Factors</CustomLink>*/}
     
      </ul>
    </nav>
  );
}

export default HeaderLCAPage;
