import React from "react";

function EditableRow({editFormData, handleEditFormChange,handleCancelClick, handleEditFormSubmit,handleSelectEFClickEdit,selectedEF }) {

  return (
    <tr> 
    <td> <select value={editFormData.type} id="type" name="type" onChange={handleEditFormChange}>
          <option value="">Select...</option>
          <option   value="My Product">My Product</option>
          <option  value="Reference">Reference</option>
    
        </select></td>
    <td> <input value={editFormData.input}type="text"  maxLength="45" required="required" placeholder="Enter value" name="input" onChange={handleEditFormChange}></input> </td>
    <td> <input value={editFormData.inputvalue} type="number" required="required" placeholder="Enter value" name="inputvalue" onChange={handleEditFormChange}></input></td>
    <td> <input value={editFormData.inputvalueunit} type="text"  maxLength="45" required="required" placeholder="Enter value" name="inputvalueunit" onChange={handleEditFormChange}></input></td>
   


    <td colSpan="3"> {/*<Link to="/lcapage/impactfactors"> <button className="efbutton" type="submit" onClick={handleSelectEFClick}>{selectedEF.phase===phase?(selectedEF.name):"Select Impact Factor"}</button></Link></td> */}
<button className="efbutton" type="submit" onClick={handleSelectEFClickEdit}>{selectedEF.name}</button></td>
    {/*<td> <input value={editFormData.impactfactor} type="text" required="required" placeholder="Enter value" name="impactfactor" onChange={handleEditFormChange}></input> </td>*/}


    <td> <textarea style={{ width: "100%"}} className="comments-column" value={editFormData.comments} type="text"  maxLength="500" required="required" placeholder="Enter value" name="comments" onChange={handleEditFormChange}></textarea></td>
    <td><button type="button" onClick={handleEditFormSubmit}>Save</button> <button type="submit" onClick={handleCancelClick}> Cancel</button></td>
</tr>
  );
}

export default EditableRow;
