import React, { useState, Fragment, useEffect } from "react";
import { Link, useMatch, useResolvedPath} from "react-router-dom";


import HeaderLCAPage from "./HeaderLCAPage";
import Axios from "axios";

function ImpactFactors({setSelectedEF,selectedEF,handleSelectEFIDEMAT}) { 
  const [idemat2024, setIdemat2024] = useState([]); // This is the original whole IDEMANT db
  const [filteredData, setFilteredData] = useState([]); //We create a nes state similar to ideam whole one but only with the filtered.
  const [searchQuery, setSearchQuery] = useState(''); // we create a state for the search wquiery so it is always updated. 
  
 
  // Fetch data from the backend
  useEffect(() => {
    Axios.get('https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/getidemat')
      .then(response => {
        setIdemat2024(response.data);
        setFilteredData(response.data); // Initially, set filteredData to all data
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Filter data based on search query
  useEffect(() => {
    const filtered = idemat2024.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredData(filtered);
  }, [searchQuery, idemat2024]);

  // Handle changes in the search input
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };



  
  
  return (

 


   <div className="app-container">

  

      {/* <HeaderLCAPage />*/}
  <div>

      
<div >




 
        <input className="SearchBar"
          type="text"
          placeholder="Search Idemat 2024 Database"
          value={searchQuery}
          onChange={handleSearchInputChange}
        />
         <button onClick={() =>handleSelectEFIDEMAT("Select Impact Factor",selectedEF.phase)}>Cancel Search</button>
        
        </div>


        <table>
          <thead>
            <tr>
              <th>Category</th>
              <th>Impact Factor</th>
              <th>Unit</th>
              <th>GHG impact (kgCO2eq) per Unit</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                <td>{item.Category}</td>
                <td>{item.Process}</td>
                <td>{item.Unit}</td>
                <td>{item.CarbonFootprint_kgGHGeq}</td>
                <td>   {/*<Link to="/lcapage/inputs"><button onClick={() =>handleSelectEF(item.Process)}>Select</button></Link></td>*/}
                <button onClick={() =>handleSelectEFIDEMAT(item.Process,selectedEF.phase)}>Select</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default  ImpactFactors; 