import React, { useState, Fragment, useEffect } from "react";

import data from "../../moc-data.json";
import isEqual from 'lodash/isEqual'; 
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import AddInputRow from "./AddInputRow";
import HeaderLCAPage from "./HeaderLCAPage";


import Axios from "axios";
import ImpactFactors from "./ImpactFactors";

//backend integration: https://www.youtube.com/watch?v=3YrOOia3-mo

// Tables buttons and edits: https://www.youtube.com/watch?v=dYjdzpZv5yc


function Inputs({selectedProject}) {

    const [inputs,setInputs]=useState(data);
    const [selectedEF, setSelectedEF] = useState({phase:"MOL", name:"Select Impact Factor",searchIsActive:false});// THIS IS USED TO chose an EF from Database component
   





   //To load the backend data when we arrive on the page
    useEffect(()=>{
        Axios.get('https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/get').then((response)=> {
            
    
        
        const dbAllInput=response.data;  //OBJECT WITH ALL THE DATA FROM DB
        const dbInput = dbAllInput.filter(item => item.inputProjectID === selectedProject.id); //WE FILTER TO WE ONLY GET THE INPUTS ASSOCIATED WITH THE SELECTED PROJECT
        
        

        //HERE I NEED TO CONVERT DBINPUT TO A NEW inputs FILE with all inputs from DB

                // Define a function to convert each object
        function convertInput(input) {
            return {
                id:input.idInputs,
                type: input.solutionReference,
                phase: input.category,
                input: input.name,
                inputvalue: parseFloat(input.value), // Convert value to number
                inputvalueunit: input.unit,
                impactfactor: input.eF,
                comments:input.comments,

                //Extra data for display
                eFUnit: input.eFUnit,
                eFGHG: parseFloat(input.eFGHG),  // Convert value to number
                inputGHGImpact: input.inputGHGImpact

                
            };
                    }

            // Map over dbInputs and apply the conversion function to each object
            const convertedInputs = dbInput.map(convertInput);

                    // We do a check to avoid infinite loops so that we update inputs only when it really changed
            if (!isEqual(convertedInputs, inputs)) { // Check if the new value is different
                setInputs(convertedInputs);
            }
            //setInputs(convertedInputs);
            console.log(convertedInputs);
            console.log("TYPE OF GHG EF IS",typeof convertedInputs.eFGHG);
        
    
    });},[inputs]);// everytime inputs is changed, useEffect runs (it's a dependency) This is handy because we get back the id of new datapoints that is defined in the backend

    
    



    
    
    const [editContactId, setEditContactId]=useState(null);
    const [addFormData,setAddFormData]=useState({
        type:"",
        input:"",
        inputvalue:0,
        inputvalueunit:"",
        impactfactor:"",
        comments:"",
        

    });
    const [editFormData, setEditFormData]=useState({
        type:"",
        input:"",
        inputvalue:0,
        inputvalueunit:"",
        impactfactor:"",
        comments:"",
        

    });

    var phase="";

    const handleSelectEFClickBOL=(phase)=>{
        
        const newSelectedEF={phase:"BOL", name:"Click on desired impact factor",searchIsActive:true} // we set this to TRUE
        setSelectedEF(newSelectedEF);
        phase="BOL";
      
        console.log("BOL");
    }
    const handleSelectEFClickMOL=(phase)=>{
       
        const newSelectedEF={phase:"MOL", name:"Click on desired impact factor",searchIsActive:true} // we set this to TRUE
        setSelectedEF(newSelectedEF);
       
     
    }
    const handleSelectEFClickEOL=(phase)=>{
      
        const newSelectedEF={phase:"EOL", name:"Click on desired impact factor",searchIsActive:true} // we set this to TRUE
        setSelectedEF(newSelectedEF);
       
    }

   const handleSelectEFClickEdit=()=>{

    const newSelectedEF={phase:"", name:editFormData.impactfactor,searchIsActive:true} // we set this to TRUE
    setSelectedEF(newSelectedEF);
        
    }

    const handleSelectEFIDEMAT = (name, phase) => {
        
        console.log("THE EF NAME IS:", name)
        //event.body.Name
        var finalSelectedEF={phase:phase, name:name, searchIsActive:false}//=selectedEF;
    
        //console.log(newSelectedEF);
        //newSelectedEF.name=name ;//"Alumina"
        //newSelectedEF.searchIsActive=false;
        setSelectedEF(finalSelectedEF);
    
       
        console.log(finalSelectedEF);
        


        //FOR THE EDIT MENU, WE CHANGE EDIT FORM DATA SO THAT WHEN WE SAVE EDIT, THE EF IS UPDATED (IF A NEW EF WAS SELECTED), IF it's not an EDIT MENU CASE, this can still happen but does not bother us

        var newEditFormData=editFormData;
        newEditFormData.impactfactor=name;
        setEditFormData(newEditFormData);
        
      };
    

    const handleEditFormChange=(event)=>{
        event.preventDefault();
        const fieldName=event.target.getAttribute("name");
        const fieldValue=event.target.value;
        const newFormData= {...editFormData}
        newFormData[fieldName]=fieldValue;
        setEditFormData(newFormData);

    }
    const handleAddFormChange = (event)=>{
        event.preventDefault();
        const fieldName=event.target.getAttribute("name");
        const fieldValue=event.target.value;
        const newFormData={...addFormData}
        newFormData[fieldName]=fieldValue;
        setAddFormData(newFormData);
        
    }
    const handleAddFormSubmit=(event,phase)=>{
        event.preventDefault();
        const newInput={
            //id: nanoid(),
            type:addFormData.type,
            input:addFormData.input,
            inputvalue:addFormData.inputvalue,
            inputvalueunit:addFormData.inputvalueunit,
            comments:addFormData.comments,


            impactfactor:selectedEF.name, // HERE WE TAKE THE EMISSION FACTOR FROM THE SELECTED EMISSION FACTOR OBJECT
            phase: phase

            
        };
        const newInputs=[...inputs, newInput];

    
        setInputs(newInputs);

        //We convert from local input to database structure and send the new inputs to the database (backend)
        const inputProjectID=selectedProject.id; // VERY IMPORTANT to make sure we attribute this input to the selected project 
        const solutionReference=newInput.type;
        const name= newInput.input;
        const value= newInput.inputvalue;
        const unit= newInput.inputvalueunit;
        const eF= newInput.impactfactor;
        const category= newInput.phase;
        const comments= newInput.comments;
        Axios.post("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/insert", {inputProjectID:inputProjectID, name: name, solutionReference:solutionReference, value:value,unit:unit,eF:eF,category: category, comments:comments}).then(()=>alert("success"));

        console.log(inputs);
    }

    const handleAddFormSubmitUse=(event)=>{
        handleAddFormSubmit(event,"MOL")
    }
    const handleAddFormSubmitProduction=(event)=>{
        handleAddFormSubmit(event,"BOL")
    }
    const handleAddFormSubmitDisposal=(event)=>{
        handleAddFormSubmit(event,"EOL")
    }
    const handleEditFormSubmit=(event,phase)=>{
        event.preventDefault();
        const editedInput={
            
            type:editFormData.type,
            input:editFormData.input,
            inputvalue:editFormData.inputvalue,
            inputvalueunit:editFormData.inputvalueunit,
            impactfactor:editFormData.impactfactor,
            comments:editFormData.comments,
            phase:phase
        };
        console.log("EDIT FORM IMPACT FACTOR NAME IS",editFormData.impactfactor )
        const newInputs=[...inputs];
        const index=inputs.findIndex((input)=>input.id===editContactId)
        newInputs[index]=editedInput;
        setInputs(newInputs);

         //We convert from local input to database structure and send the new inputs to the database (backend)
         const idInputs=inputs[index].id;
         console.log("index=");
         console.log(inputs[index].id);


         const inputProjectID=selectedProject.id;  // VERY IMPORTANT to make sure we attribute this input to the selected project 
         const solutionReference=editedInput.type;
         const name= editedInput.input;
         const value= editedInput.inputvalue;
         const unit= editedInput.inputvalueunit;
         const eF= editedInput.impactfactor;
         const category= editedInput.phase;
         const comments=editedInput.comments;
         Axios.post("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/edit", {idInputs:idInputs, inputProjectID:inputProjectID, name: name, solutionReference:solutionReference, value:value,unit:unit,eF:eF,category: category, comments:comments}).then(()=>alert("success"));
 
         console.log(inputs);
        setEditContactId(null);
    }
    const handleEditFormSubmitUse=(event)=>{
        handleEditFormSubmit(event,"MOL")
    }

    const handleEditFormSubmitProduction=(event)=>{
        handleEditFormSubmit(event,"BOL")
    }

    const handleEditFormSubmitDisposal=(event)=>{
        handleEditFormSubmit(event,"EOL")
    }

    const handleEditClick=(event, contact)=>{
        event.preventDefault();
        setEditContactId(contact.id);

        const formValues={
        type:contact.type,
        input: contact.input,
    
        inputvalue:contact.inputvalue,
        inputvalueunit:contact.inputvalueunit,
        impactfactor:contact.impactfactor,
        comments:contact.comments
        }
        setEditFormData(formValues);

        //TO MAKE SURE THAT THE BUTTON SHOWS THE INPUTS EF
        const newSelectedEF={phase:"", name:contact.impactfactor,searchIsActive:false} // we set this to False because we don't want to open the search menu right away
        setSelectedEF(newSelectedEF);
        
    }
    const handleCancelClick=()=>{
        setEditContactId(null);
        
    }
    const handleDelete=(inputId)=>{
    
        const newInputs=[...inputs];
        const index=inputs.findIndex((input)=>input.id===inputId)

        const idInputs=inputs[index].id;
    
        newInputs.splice(index, 1);
        setInputs(newInputs);

        Axios.delete("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/delete", {
            data: { idInputs: idInputs } // Specify the idInputs of the line to delete
        }).then(() => {
            alert("Line deleted successfully");
        }).catch(error => {
            console.error("Error deleting line:", error);
        });
        


    }

    return (
    
       

    <div className="app-container">


        <HeaderLCAPage selectedProject={selectedProject} />

        <div className="container">

     <div><h2> {selectedProject.projectName}</h2></div>
      

        
     


        <div>
        
        <h3><em>Production / Manufacturing</em></h3>
    
       
        <table>
            <thead>
                <tr>
                    <th > Solution/Reference</th>
                    <th > Material/Process </th>
                    <th > Value </th>
                    <th > Value Unit</th>
                    <th > Impact Factor</th>
                    <th > ImpactFactor Unit</th>
                    <th > Impact Factor GHG Impact per Unit</th>
                    <th className="comments-column">  Comments </th>
                    <th> Actions</th>
                </tr>
            </thead>
            <tbody>
                {inputs.filter(contact => contact.phase === "BOL").map((contact)=>(
                    <Fragment>
                        {editContactId===contact.id?(<EditableRow handleEditFormSubmit={handleEditFormSubmitProduction} handleCancelClick={handleCancelClick} editFormData={editFormData} handleEditFormChange={handleEditFormChange} handleSelectEFClickEdit={handleSelectEFClickEdit} selectedEF={selectedEF}/>):<ReadOnlyRow handleDelete={handleDelete} handleEditClick={handleEditClick} contact={contact}/> }
                    
                    </Fragment>
                    
                    

                ))}
                <AddInputRow  handleAddFormChange={handleAddFormChange} handleAddFormSubmit={handleAddFormSubmitProduction} handleSelectEFClick={handleSelectEFClickBOL} selectedEF={selectedEF} phase={"BOL"}/>
              
                
            </tbody>
        </table>

        <h3><em>Use</em></h3>

        <table>
            <thead>
                <tr>
                    <th > Solution/Reference</th>
                    <th > Energy Consumption/Process </th>
                    <th > Value </th>
                    <th > Value Unit</th>
                    <th > Impact Factor</th>
                    <th > ImpactFactor Unit</th>
                    <th > Impact Factor GHG Impact per Unit</th>
                    <th > Comments </th>

                    <th> Actions</th>
                </tr>
            </thead>
            <tbody>
                {inputs.filter(contact => contact.phase === "MOL").map((contact)=>(
                    <Fragment>
                        {editContactId===contact.id?(<EditableRow  handleEditFormSubmit={handleEditFormSubmitUse} handleCancelClick={handleCancelClick} editFormData={editFormData} handleEditFormChange={handleEditFormChange}/>):<ReadOnlyRow handleDelete={handleDelete} handleEditClick={handleEditClick} contact={contact}/> }
                    
                    
                    
                    </Fragment>
                    
                    

                ))}
                <AddInputRow handleAddFormChange={handleAddFormChange} handleAddFormSubmit={handleAddFormSubmitUse} handleSelectEFClick={handleSelectEFClickMOL} selectedEF={selectedEF} phase={"MOL"}/>
              
                
            </tbody>
        </table>

        <h3><em>Disposal</em></h3>

    <table>
    <thead>
        <tr>
            <th > Solution/Reference</th>
            <th > Energy Consumption/Process </th>
            <th > Value </th>
            <th > Value Unit</th>
            <th > Impact Factor</th>
            <th > ImpactFactor Unit</th>
            <th > Impact Factor GHG Impact per Unit</th>
            <th > Comments </th>
            <th> Actions</th>
        </tr>
    </thead>
    <tbody>
        {inputs.filter(contact => contact.phase === "EOL").map((contact)=>(
            <Fragment>
                {editContactId===contact.id?(<EditableRow handleEditFormSubmit={handleEditFormSubmitDisposal} handleCancelClick={handleCancelClick} editFormData={editFormData} handleEditFormChange={handleEditFormChange}/>):<ReadOnlyRow handleDelete={handleDelete} handleEditClick={handleEditClick} contact={contact}/> }
            
            
            
            </Fragment>
            
            

        ))}
        <AddInputRow handleAddFormChange={handleAddFormChange} handleAddFormSubmit={handleAddFormSubmitDisposal} handleSelectEFClick={handleSelectEFClickEOL} selectedEF={selectedEF} phase={"EOL"}/>
      
        
    </tbody>
</table> </div>
       
       
    

{selectedEF.searchIsActive && <div className="dbsearch"><ImpactFactors setSelectedEF={setSelectedEF} selectedEF={selectedEF}  handleSelectEFIDEMAT={handleSelectEFIDEMAT}/></div>}

     </div>  </div>
      
    );
  }

export default Inputs;
