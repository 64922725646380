import React, { useState } from "react";
import Note from "./Note";

import CreateArea from "./CreateArea";



function AppKeeper({user}) {
  
  
  const [notes, setNotes] = useState([]);

  function addNote(newNote) {
    console.log("ADD NOTE TRIGGERED");
    console.log("user IS", user);
    setNotes(prevNotes => {
      return [...prevNotes, newNote];
    });
  }

  function deleteNote(id) {
    setNotes(prevNotes => {
      return prevNotes.filter((noteItem, index) => {
        return index !== id;
      });
    });
  }

  return (
    
    <div className="nonlcalabtab">
      
      <CreateArea onAdd={addNote} user={user}/>
      {notes.map((noteItem, index) => {
        return (
          <Note
            key={index}
            id={index}
            title={noteItem.title}
            content={noteItem.content}
            onDelete={deleteNote}
          />
        );
      })}
  
    </div>
  );
}

export default AppKeeper;
