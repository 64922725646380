import React, { useState, Fragment, useEffect } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import downloadbutton from '../../media/download.png';

import {Chart as Chartjs} from "chart.js/auto";
import{Bar, Doughnut} from "react-chartjs-2";


import isEqual from 'lodash/isEqual'; 
import HeaderLCAPage from "./HeaderLCAPage";

import Axios from "axios";

//backend integration: https://www.youtube.com/watch?v=3YrOOia3-mo

// Tables buttons and edits: https://www.youtube.com/watch?v=dYjdzpZv5yc





function Outputs({selectedProject}) {
  const [colourSolution, setColourSolution] = useState([]);
  const [colourRef, setColourRef] = useState([]);

  const [showDownloads,setShowDownloads]=useState(false);


  


  const [results,setResults]=useState(
     {
    SOL: {
        BOL: {
            GHG:0
        },
        MOL: {
            GHG:0
        },
        EOL: {
          GHG:0
      },
      TOTAL: {
        GHG:0
    }
      
    },
    REF: {
      BOL: {
          GHG:0
      },
      MOL: {
          GHG:0
      },
      EOL: {
        GHG:0
    },
    TOTAL: {
      GHG:0
  }
  
  }
    
  })

  const [organizedResults,setOrganizedResults]=useState(
   [ 
     {SOL:{BOL:0,
                MOL:0,
                EOL:0},
      REF:{BOL:0,
               MOL:0,
                EOL:0},
      MetaData:{Impact:"Climate Change",
                Unit:"kgCO2eq"
                }},

       {SOL:{BOL:0,
                MOL:0,
                EOL:0},
            REF:{BOL:0,
                  MOL:0,
                  EOL:0},
                  MetaData:{Impact:"PEF Total",
                  Unit:"Pt"
                  }}
            ]
)

function downloadMenu (){
  const temp=!showDownloads;
  setShowDownloads(temp);
}

  function reorganiseResults(results){// WE create a function to REORGANISE the results so THAT IT IS EASIER plot by IMpact Category


    //WE CREATE THE LIST OF INDIVIDUAL GHG RESULTS FOR THE PIE CHART, WE ALSO NEED THE LIST OF THE NAMES
    const individualsolvalueGHG = results.INDIVIDUAL
    .filter(item => item.solutionReference === 'My Product') // Filter objects with solutionReference 'My Product'
    .map(item => item.GHGImpact); 
    

    const individualsolname = results.INDIVIDUAL
    .filter(item => item.solutionReference === 'My Product') // Filter objects with solutionReference 'My Product'
    .map(item => item.name); 

  

//SAME FOR REF
    const individualrefvalueGHG = results.INDIVIDUAL
    .filter(item => item.solutionReference === 'Reference') // Filter objects with solutionReference 'My Product'
    .map(item => item.GHGImpact); 
  

    const individualrefname = results.INDIVIDUAL
    .filter(item => item.solutionReference === 'Reference') // Filter objects with solutionReference 'My Product'
    .map(item => item.name); 

 



//SAME FOR PEF: SOL AND REF
    const individualsolvaluePEF = results.INDIVIDUAL
    .filter(item => item.solutionReference === 'My Product') // Filter objects with solutionReference 'My Product'
    .map(item => item.PEFImpact); 
    


    const individualrefvaluePEF = results.INDIVIDUAL
    .filter(item => item.solutionReference === 'Reference') // Filter objects with solutionReference 'My Product'
    .map(item => item.PEFImpact); 
    


    //NOW WE CAN ALSO ALREADY DEFINE THE COLOUR STRUCTURE OF THE PIE CHARTS, BASED ON THE NUMBER OF EACH INPUT IN EACH PHASE
    //THE FIRST 3 COLOURS ARE FIXED: FOR BOL, MOL and EOL, THEN the rest is depending on the nzmber of specific inputs in BOL, MOL, EOL

    function rgba(r, g, b, a) {
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    }

    var colourSolutionTemp=["rgba(37, 101, 32, 0.8)","rgba(16,165, 185,0.8)","rgba(238, 162, 85,0.8)"];

    const rBOL=37;
    const gBOL=101;
    const bBOL=32;
    const rMOL=16;
    const gMOL=165;
    const bMOL=185;
    const rEOL=238;
    const gEOL=162;
    const bEOL=85;


    // WE COUNT ALL BOL & SOL
const filteredArray = results.INDIVIDUAL.filter(obj => obj.category === 'BOL' && obj.solutionReference === 'My Product');
const numberofBOLSOL = filteredArray.length;


// WE COUNT ALL MOL & SOL
const filteredArray2 = results.INDIVIDUAL.filter(obj => obj.category === 'MOL' && obj.solutionReference === 'My Product');
const numberofMOLSOL = filteredArray2.length;


// WE COUNT ALL EOL & SOL
const filteredArray3 = results.INDIVIDUAL.filter(obj => obj.category === 'EOL' && obj.solutionReference === 'My Product');
const numberofEOLSOL = filteredArray3.length;

//WE ADJUSTE THE COLOUR ARRAY ACCORDINGLY
// WE ADD X TIMES A BOL COLOUR VARIATION (Random a)
for (let i = 0; i < numberofBOLSOL; i++) {
  colourSolutionTemp.push(rgba(rBOL,gBOL,bBOL,(Math.random())));
}
//SAME FOR MOL
for (let i = 0; i < numberofMOLSOL; i++) {
  colourSolutionTemp.push(rgba(rMOL,gMOL,bMOL,(Math.random())));
}
//SAME FOR EOL
for (let i = 0; i < numberofEOLSOL; i++) {
  colourSolutionTemp.push(rgba(rEOL,gEOL,bEOL,(Math.random())));
}

setColourSolution(colourSolutionTemp);





    //WE DO SAME WITH REF

    var colourRefTemp=["rgba(37, 101, 32, 0.8)","rgba(16,165, 185,0.8)","rgba(238, 162, 85,0.8)"];
        // WE COUNT ALL BOL & SOL
const filteredArray4 = results.INDIVIDUAL.filter(obj => obj.category === 'BOL' && obj.solutionReference === 'Reference');
const numberofBOLREF = filteredArray4.length;


// WE COUNT ALL MOL & SOL
const filteredArray5 = results.INDIVIDUAL.filter(obj => obj.category === 'MOL' && obj.solutionReference === 'Reference');
const numberofMOLREF = filteredArray5.length;


// WE COUNT ALL EOL & SOL
const filteredArray6 = results.INDIVIDUAL.filter(obj => obj.category === 'EOL' && obj.solutionReference === 'Reference');
const numberofEOLREF = filteredArray6.length;

//WE ADJUSTE THE COLOUR ARRAY ACCORDINGLY
// WE ADD X TIMES A BOL COLOUR VARIATION (Random a)
for (let i = 0; i < numberofBOLREF; i++) {
  colourRefTemp.push(rgba(rBOL,gBOL,bBOL,(Math.random())));
}
//SAME FOR MOL
for (let i = 0; i < numberofMOLREF; i++) {
  colourRefTemp.push(rgba(rMOL,gMOL,bMOL,(Math.random())));
}
//SAME FOR EOL
for (let i = 0; i < numberofEOLREF; i++) {
  colourRefTemp.push(rgba(rEOL,gEOL,bEOL,(Math.random())));
}

setColourRef(colourRefTemp);






    const organizedResults = [  
     {SOL:{BOL:results.SOL.BOL.CarbonFootprint_kgGHGeq,
                MOL:results.SOL.MOL.CarbonFootprint_kgGHGeq,
                EOL:results.SOL.EOL.CarbonFootprint_kgGHGeq},
      REF:{BOL:results.REF.BOL.CarbonFootprint_kgGHGeq,
               MOL:results.REF.MOL.CarbonFootprint_kgGHGeq,
                EOL:results.REF.EOL.CarbonFootprint_kgGHGeq},
            MetaData:{Impact:"Climate Change",
                Unit:"kgCO2eq"
                },
      INDIVIDUALSOLVALUES:individualsolvalueGHG,
      INDIVIDUALSOLNAMES:individualsolname,
      INDIVIDUALREFVALUES:individualrefvalueGHG,
      INDIVIDUALREFNAMES:individualrefname,

      
      


              
              
              },

       {SOL:{BOL:results.SOL.BOL.pefTotal_pt,
                MOL:results.SOL.MOL.pefTotal_pt,
                EOL:results.SOL.EOL.pefTotal_pt},
            REF:{BOL:results.REF.BOL.pefTotal_pt,
                  MOL:results.REF.MOL.pefTotal_pt,
                  EOL:results.REF.EOL.pefTotal_pt},
                
                  MetaData:{Impact:"PEF Total",
                  Unit:"Pt"
                  },
                  INDIVIDUALSOLVALUES:individualsolvaluePEF,
      INDIVIDUALSOLNAMES:individualsolname,
      INDIVIDUALREFVALUES:individualrefvaluePEF,
      INDIVIDUALREFNAMES:individualrefname,
                
                
                }, 
            ]

      console.log('PRGANISED RESULTS AREE:', organizedResults);
      console.log('RESULTs.INIVIDUAL', results.INDIVIDUAL);


      //NOW WE CAN ALSO ADD OTHER IMPACTS OTher THAN GHG AND PEF:
      const keys = Object.keys(results.SOL.BOL).slice(2); // We get an array with all the impact factors, and we remove GHG end PEF total since we already have them
      for (const key of keys ) { 
        const impact=key.replace("_pt", "").replace(/_/g, " ");
        const temp={SOL:{BOL:results.SOL.BOL[key],
        MOL:results.SOL.MOL[key],
        EOL:results.SOL.EOL[key]},
    REF:{BOL:results.REF.BOL[key],
          MOL:results.REF.MOL[key],
          EOL:results.REF.EOL[key]},
        
          MetaData:{Impact:impact,
          Unit:"Pt" //It's always "Pt" for the PEF indicators
          },
         
        
        
        }

        organizedResults.push(temp) // WE ADD The Emissions factor data to the organised results array.
      
      }





console.log("ORGANISED FUULLLL RESULTS", organizedResults)


return organizedResults;
  }


function downloadableResult(results){ //Input should be results.SOL or results.REF
  const outputArray = [];

for (const metric in results.BOL) { //We iterate on each metric, which represents a row, and we add the values for BOL MOL EOL TOTAL for that metric

  const row = {
    'Impact metric': metric,
    BOL: results.BOL[metric],
    MOL: results.MOL[metric],
    EOL: results.EOL[metric],
    TOTAL: results.TOTAL[metric]
  };
  outputArray.push(row);
}

console.log("DOWNLOADBLE RESULTS",outputArray);

return outputArray


}

 
 //const [inputs,setInputs]=useState(null);

   //To load the backend data when we arrive on the page ATtnetion; HERE THIS IS A COPY PASTE FROM input code IT WOULD BE BETTER TO IMPORT IT? OR DEFINE IT IN A MORE CENTRAL PLACE AND IMPORT TO BOTH INPUT AND OUTPUT?
    useEffect(()=>{

      const projectID = selectedProject.id;
      console.log("PROJECT ID SENT TO BACKEN", projectID)
        Axios.get(`https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/getTotalResults?variable=${projectID}`).then((response)=> {

        console.log("SELECTED PROJECT ID:");
        console.log(selectedProject.id);

        setResults(response.data); //Backend sends us the results it claculated
        console.log("RRESULTS IS", response.data)

        setOrganizedResults(reorganiseResults(response.data));
        console.log("REORGANISED RESULTS", reorganiseResults(response.data))
    
    });},[]);

   

    return (
    
       

     <div className="app-container">


        <HeaderLCAPage selectedProject={selectedProject}/>

        <div className="container">
        <div style={{display:"flex", direction:"column", alignItems:"center"}}><h2> {selectedProject.projectName}</h2> </div>
        {/* <CSVDownload data={"JSKFJKS"} target="_blank"/>  THIS WOULDBE TO DIRECTLY DOWNLOAD WITHOUT NEEDONG TO CLICK; JUST BY LANDING ON PAGE*/}
       
        <p style={{ cursor: "pointer", borderRadius:"5px", height:"0.5rem", fontSize:"0.5rem",padding:"0", alignContent:"center", marginBottom:"10px"}}onClick={downloadMenu}><em>Show CSV Download Options</em></p>
        {showDownloads &&(<div className="DownloadBox"> 
        
        <div style={{display:"flex", direction:"row", alignItems:"center"}}><div className="csvlink"><CSVLink data={results.SOL?(downloadableResult(results.SOL)):[{name: "Failure",Bol:0,mol:0,eol:0},{Bol:0,mol:0,eol:0}] } > 
        <button><img style={{width:"1rem", height:"auto"}}src={downloadbutton} alt="downloadbutton" /></button>
</CSVLink></div><p> My Product</p></div>
<div style={{display:"flex", direction:"row", alignItems:"center"}}><div className="csvlink"><CSVLink className="csvlink" data={results.REF?(downloadableResult(results.REF)):[{name: "Failure",Bol:0,mol:0,eol:0},{Bol:0,mol:0,eol:0}] } filename={"reference_results.csv"} > 
  <button><img style={{width:"1rem", height:"auto"}}src={downloadbutton} alt="downloadbutton" /></button>
</CSVLink></div><p>Reference</p></div>

<div style={{display:"flex", direction:"row", alignItems:"center"}}><div className="csvlink"><CSVLink className="csvlink" data={results.INDIVIDUAL?(results.INDIVIDUAL.slice(2)):[{name: "Failure",Bol:0,mol:0,eol:0},{Bol:0,mol:0,eol:0}] } filename={"granular_results.csv"} > 
  <button className="Download"><img style={{width:"1rem", height:"auto"}}src={downloadbutton} alt="downloadbutton" /></button>
</CSVLink></div><p> Granular results (all inputs) </p></div></div>)}
{/* ABOVE; WE NEED TO CONVERT THE RESULTS.CSV OBJECT TO A SIMPLE ARRAY IN ORDER TO DOWNNLOAD IT*/}

        <h1> Main Metrics (Climate Change and total PEF)</h1>

       
        <table>
            <thead>
                <tr>
                    <th >Phase </th>
                    <th > Solution Total GHG Impact (kgCO2eq) </th>
                    <th > Reference Total GHG Impact (kgCO2eq) </th>

                    <th > Solution Total PEF Impact (Pt) </th>
                    <th > Reference Total PEF Impact (Pt) </th>
                   
                </tr>
            </thead>
            <tbody>

            <tr>
                <td> BOL</td>
                <td> {results.SOL.BOL.CarbonFootprint_kgGHGeq} </td>
                <td> {results.REF.BOL.CarbonFootprint_kgGHGeq} </td>
                <td> {results.SOL.BOL.pefTotal_pt} </td>
                <td> {results.REF.BOL.pefTotal_pt} </td>
        
            </tr>
            <tr>
                <td> MOL</td>   
                <td> {results.SOL.MOL.CarbonFootprint_kgGHGeq} </td>
                <td> {results.REF.MOL.CarbonFootprint_kgGHGeq} </td>
                <td> {results.SOL.MOL.pefTotal_pt} </td>
                <td> {results.REF.MOL.pefTotal_pt} </td>
                       
            </tr>
            <tr>
                <td> EOL</td>    
                <td> {results.SOL.EOL.CarbonFootprint_kgGHGeq} </td>
                <td> {results.REF.EOL.CarbonFootprint_kgGHGeq} </td>   
                <td> {results.SOL.EOL.pefTotal_pt} </td>
                <td> {results.REF.EOL.pefTotal_pt} </td>
            </tr>
            <tr>
                <td> Total</td>
                <td> {results.SOL.TOTAL.CarbonFootprint_kgGHGeq} </td>
                <td> {results.REF.TOTAL.CarbonFootprint_kgGHGeq}</td>  
                <td> {results.SOL.TOTAL.pefTotal_pt} </td>
                <td> {results.REF.TOTAL.pefTotal_pt}</td>        
            </tr>

            

                
                    

    
              
              
                
            </tbody>
        </table>



       {/* https://www.youtube.com/watch?v=6q5d3Z1-5kQ*/}



       {organizedResults.slice(0, 2).map((ImpactMeasured, index) => ( //WE MAP FOR EACH IMPACT CATEGORY, GHG, THEN PEF (slice 0,2 to take only the first 2 elements)

        <div className="graphsarea">

        <div className="graphCard">
          <Bar data={{labels:["BOL ("+ImpactMeasured.MetaData.Unit+")","MOL ("+ImpactMeasured.MetaData.Unit+")","EOL ("+ImpactMeasured.MetaData.Unit+")"],
        datasets:[{label:"Solution", data:[ImpactMeasured.SOL.BOL, ImpactMeasured.SOL.MOL, ImpactMeasured.SOL.EOL],borderRadius:5, backgroundColor:["rgba(84, 162, 58,0.8)"]},{label:"Reference", data:[ImpactMeasured.REF.BOL,ImpactMeasured.REF.MOL,ImpactMeasured.REF.EOL],borderRadius:5,},]}}
        options={{
          plugins: {
            title: {
              display: true,
              text: ImpactMeasured.MetaData.Impact+' - Comparison', 
              font: {
                size: 18 // Adjust font size as needed
              }
            }
          }}}
        
        />
       </div>

<div className="graphCard">
       
          <Doughnut data={{labels:["BOL","MOL","EOL"].concat(ImpactMeasured.INDIVIDUALSOLNAMES),//Here we add three emplty labels so that the first 3 are assigned to MOL,BOL,EOL and the rest to the detailed (For the legends in the pie chart)
        datasets:[{label:"Solution", data:[0,0,0].concat(ImpactMeasured.INDIVIDUALSOLVALUES),borderRadius:5,backgroundColor:colourSolution?colourSolution:[] },{label:"Solution", data:[ImpactMeasured.SOL.BOL,ImpactMeasured.SOL.MOL,ImpactMeasured.SOL.EOL].concat(0,0,0),borderRadius:5,backgroundColor:colourSolution?colourSolution:[]}
                  ]}}
        options={{
          plugins: {
            title: {
              display: true,
              text: ImpactMeasured.MetaData.Impact+" ("+ImpactMeasured.MetaData.Unit+") "+' - My Product', // Your desired title
              font: {
                size: 18 // Adjust font size as needed
              }
            }
          }}}
        
        />
        </div>

        <div className="graphCard">
       
          <Doughnut data={{labels:["BOL","MOL","EOL"].concat(ImpactMeasured.INDIVIDUALREFNAMES),//Here we add three emplty labels so that the first 3 are assigned to MOL,BOL,EOL and the rest to the detailed (For the legends in the pie chart)
        datasets:[{label:"Solution", data:[0,0,0].concat(ImpactMeasured.INDIVIDUALREFVALUES),borderRadius:5,backgroundColor:colourRef?colourRef:[]},{label:"Solution", data:[ImpactMeasured.REF.BOL,ImpactMeasured.REF.MOL,ImpactMeasured.REF.EOL].concat(0,0,0),borderRadius:5,backgroundColor:colourRef?colourRef:[]}]}}
        options={{
          plugins: {
            title: {
              display: true,
              text: ImpactMeasured.MetaData.Impact+" ("+ImpactMeasured.MetaData.Unit+") "+' - Reference', // Your desired title
              font: {
                size: 18 // Adjust font size as needed
              }
            }
          }}}
        
        />
        </div>

        </div> 
        
        )
        
        )} 


<h1> PEF specific Metrics </h1>
<div className="graphsarea">    
{organizedResults.slice(2).map((ImpactMeasured, index) => ( //WE MAP THE REST OF THE IMPACTS, ONLY FOR THE BAR CHART): WE REMOVE THE FIRST 2 ELEMENTS WITH SLICE 2



<div className="graphCard">
  <Bar data={{labels:["BOL ("+ImpactMeasured.MetaData.Unit+")","MOL ("+ImpactMeasured.MetaData.Unit+")","EOL ("+ImpactMeasured.MetaData.Unit+")"],
datasets:[{label:"Solution", data:[ImpactMeasured.SOL.BOL, ImpactMeasured.SOL.MOL, ImpactMeasured.SOL.EOL],borderRadius:5, backgroundColor:["rgba(59, 100, 58,0.8)"]},{label:"Reference", data:[ImpactMeasured.REF.BOL,ImpactMeasured.REF.MOL,ImpactMeasured.REF.EOL],borderRadius:5,},]}}
options={{
  plugins: {
    title: {
      display: true,
      text: ImpactMeasured.MetaData.Impact+' - Comparison', 
      font: {
        size: 18 // Adjust font size as needed
      }
    }
  }}}

/>
</div>


)

)} 
   </div> 
    </div>
    </div>    
    );
  }

export default Outputs;
