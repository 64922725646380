import React, { useImperativeHandle } from "react";
import { Link, useMatch, useResolvedPath} from "react-router-dom";
import logo from '../media/Complete_White.png';

function Header({user}) {


// Function customLInk to see if our path matches a specific path, in order for us to style the button that is active differently, we use props to communicatebetween those
  function CustomLink({to, children, ...props}){
    const resolvedPath=useResolvedPath(to);
    const currentPath = useResolvedPath();
    //const isActive=useMatch({path:resolvedPath.pathname, end: true})
    //const isActive = currentPath.pathname.startsWith(resolvedPath.pathname);

    const isActive = useMatch({path: resolvedPath.pathname, end: true}) ||
                     (currentPath.pathname.includes("lcapage") && resolvedPath.pathname.includes("lcapage")); //IF it's a match, we set it to active, OR if we have "lcapage" in both urls, this way LCA LAB is active when we are in all LCA LAB subtabs
                    

    return (
      <li className={isActive?"active":""}>
      <Link to={to}{...props}>{children}</Link>
      </li>

    )

  }

  return (
    
    <nav>
      {/* <h1>QuickImpact</h1>  GreenFocus_logo_Canva  ../media/Complete_White.png*/}
      <img src={logo} alt="Logo" />
      <ul> 

      {/* <CustomLink to="/">Home</CustomLink> */}
      
      <CustomLink to="/">Impact Lab</CustomLink>
      <CustomLink to="/help">Help</CustomLink>
      <CustomLink to="/loginsignup">{user===null?("Login or Sign Up"):user.displayName}</CustomLink>
     
      </ul>
     
    </nav>
  );
}

export default Header;
