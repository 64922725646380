import React, { useState, Fragment, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Help from"./Help/Help";
import AiAssistance from "./Lcapage/AiAssistance";
import Notes from"./Lcapage/Notes";
import AppKeeper from "./AppKeeper";
import Inputs from"./Lcapage/Inputs";
import Project from "./Lcapage/Project";
import Outputs from "./Lcapage/Outputs";
import {Route, Routes} from "react-router-dom";
import ImpactFactors from "./Lcapage/ImpactFactors";
import Loginpage from "./Loginpage/Loginpage";
import Axios from "axios";

function App() {

  const [user,setUser]=useState(null);
  const [userID,setUserID]=useState(null);

  // useEffect(()=>{

//    const getUser=()=>{
//   Axios.get('http://localhost:3001/auth/login/success', { withCredentials: true })
//   .then(response => {console.log("MADAME ZOZOUA BONSOIR");
//     console.log("RESPONSE USER GET FROM BACKEND:", response.status);
//     if (response.status === 200) {
//       setUser(response.data.user); // Assuming the user object is directly available in the response
//       setUserID(response.data.userId);  // Here we also get the userID fetched by our backend from our DB
//     } else {
//       console.log("status is not 200:", response.status);
//       throw new Error("Authentication Failed");
//     }
//   })
//   .catch(error => {
//     console.error('Error with authentication:', error);
//   });
// }

//   getUser();

  


  
      
  // },[]);

  console.log("USER IS", user);
  console.log("USER ID IS", userID);

  //InterComponent Variables needed in different components that we pass ass props to the components
  const [selectedProject, setSelectedProject] = useState(0);// THIS IS USED TO PASS THE SELECTED PROJECT FROM/TO PROJECT COMPONENT TO INPUT COMPONENT VIA APP.JSX PARENT COMPONENT
  const [loggedUser,setLoggedUser]=useState(null); //THIS IS TO BE USED TO PASS THE USER ID TO DIFFERENT COMPONENTS USING PROPS (SIMILARLY TO PROJECT ID ABOVE)

  //const [inputs,setInputs]=useState(data); // TO PASS THE ACTIVE INPUTS FROM INPUTS COMPONENT TO OUTPUTS COMPONENT

  //const [selectedEF, setSelectedEF] = useState({phase:"MOL", name:"Select Impact Factor",searchIsActive:false});// THIS IS USED TO chose an EF from Database component
 
  return (
    
    <div>
      <Header user={user} />
     <div>
<Routes>
  {/* <Route path="/" element={<AppKeeper user={user}/>}/> */}
  <Route path="/help" element={<Help/>}/>
    {/*<Route path="/lcapage" element={<Project selectedProject={selectedProject} setSelectedProject={setSelectedProject}  userID={userID} setUserID={setUserID}/>}/> */}

  <Route path="/" element={<Project selectedProject={selectedProject} setSelectedProject={setSelectedProject} userID={userID} setUserID={setUserID} setUser={setUser}/>}/>
  <Route path="/lcapage/inputs" element={<Inputs selectedProject={selectedProject}/>}/>
  <Route path="/lcapage/outputs" element={<Outputs selectedProject={selectedProject} />}/>
  <Route path="/lcapage/Notes" element={<Notes selectedProject={selectedProject} setSelectedProject={setSelectedProject} />}/>
  <Route path="/lcapage/aiassistance" element={<AiAssistance selectedProject={selectedProject} />}/>
  <Route path="/loginsignup" element={<Loginpage user={user}/>}/>
  {/*<Route path="/lcapage/impactfactors" element={<ImpactFactors/>}/>*/}
</Routes>


     </div>
     
     {/* <Footer className="footer"/> */}


      
      
    </div>
    
  );
}

export default App;
