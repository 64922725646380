import React, { useState, Fragment, useEffect } from "react";
import googlelogo from '../../media/google.png';
import githublogo from '../../media/github.png';
import onlysymbol from '../../media/OnlySymbol.png';
//import{gapi} from 'gapi-script'; //OLD
import Loginbutton from "./Loginbutton";
import Logoutbutton from "./Logoutbutton";

////const clientId="366415380412-latg5l0lq2mjbftlr0er7ni7c89csl2d.apps.googleusercontent.com";


function Loginpage({user}) {

  // useEffect(()=>{//OLD
  //   function start(){
  //     gapi.client.init({clientId:clientId,scope:""})
  //   };
  //   gapi.load('client:auth2', start);

  // });


  const google=()=>{
  //   window.open("http://localhost:3001/auth/google", "_self") //https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/
  window.open("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/auth/google", "_self")
  }
  const github=()=>{
    window.open("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/auth/github", "_self")
  }
  const logOut=()=>{
    window.open("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/auth/logout", "_self")
  }
  
  return (
    <div className="app-container loginpage">
       <div className="loginbox">
       <img style={{width:"12rem", height:"auto"}}src={onlysymbol} alt="GFlogo" />
       {user? <div>
      
    
    
  
      

      <button className="loginButton Google" onClick={logOut}>LOG OUT</button></div>:
      
       <div>

      <h1>Log in or Sign up</h1>
      
      <p  style={{textAlign: 'justify' , fontSize:"1rem"}}>Green Focus <em>Impact Lab</em> can be accessed via Google or Github.</p>
        
        
      <button className="loginButton Google" onClick={google}> <img className="loginbuttoncontent" src={googlelogo} alt="googleLogo" /> <div className="loginbuttoncontent">LOG IN / SIGN UP WITH GOOGLE</div></button>
      <button className="loginButton Github" onClick={github}> <img className="loginbuttoncontent" src={githublogo} alt="githublogo" /><div className="loginbuttoncontent">LOG IN / SIGN UP WITH GITHUB</div> </button></div>
}
    </div></div>
  );
}

export default Loginpage;
