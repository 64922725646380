import React, { useState, Fragment, useEffect } from "react";
import { Link } from 'react-router-dom';

import isEqual from 'lodash/isEqual'; 
import HeaderLCAPage from "./HeaderLCAPage";


// CARDS INSPIRATION:https://www.youtube.com/watch?v=mVYCVq6jMb4

import Axios from "axios";







function Project({ selectedProject, setSelectedProject, userID, setUserID, setUser}) {



    // setUser(response.data.user); 
   // setUserID(null);  

    


    const [myprojects, setMyprojects] = useState([]); 
    //const [selectedProject, setSelectedProject] = useState(0); // Variable to store the the selected project, to set by default to the prop sent by app.jsx??
    const [newProject, setNewProject] = useState({
        id:null,
        
        projectName:"",
        description:"",
       numberofalternatives:0,
        midpoints:"",
        projectUserID:userID, // IMPORTANT TO DEFINE THIS HERE BECAUSE IT WONT BE DEFINED IN THE ENTRIES FOR NEW PROJECTS
        projectNotes:"",
    });
   

    const [editMode, setEditMode] = useState(false); 
    const [newProjectMenu, setNewProjectMenu] = useState(false); 
    const [editProjectMenu, setEditProjectMenu] = useState(false); 





    const [editProjectData, setEditProjectData]=useState({

        id:null,
        
        
        projectName:"",
        description:"",
       numberofalternatives:2,
        midpoints:"",
        projectUserID:null,
    });

    // Fetch data from the backend when we arrive on page
  useEffect(() => {
    //WE GET USER INFO AND ID WHen LOADing the pAge



//         Axios.get('https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/auth/login/success', { withCredentials: true })
//         .then(response => {console.log("Got User info from backend");
//           console.log("RESPONSE USER GET FROM BACKEND:", response.status);
//           if (response.status === 200) {
//             setUser(response.data.user); // Assuming the user object is directly available in the response
//             setUserID(response.data.userId);  // Here we also get the userID fetched by our backend from our DB
//           } else {
//             console.log("status is not 200:", response.status);
//             throw new Error("Authentication Failed");
//           }
//         })
//         .catch(error => {
//           console.error('Error with authentication:', error);
//         })
           
        
       

//  //WE CAN THEN LOAD THE PROJECTS THAT BELONG TO THE USER
//         .then(() => {
//     console.log("USER ID IN PROJECT VIEW LOAD IS", userID);



   
  
    // return 
        Axios.get(`https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/getprojects/${userID}`)//})
      .then(response => {


        console.log("RESPONSE FROM BACKEND ", response.data)


        //const filteredProjects = response.data.filter(project => project.projectUserID === userID );  // We filter so we only show the projects that match the user ID

      // We do a check to avoid infinite loops so that we update the projects only when it really changed
    //   if (!isEqual(filteredProjects, myprojects)) { // Check if the new value is different
    //     setMyprojects(filteredProjects);

       if (!isEqual(response.data, myprojects)) { // Check if the new value is different
        setMyprojects(response.data);
        
        
    }
    console.log("PROJECTS LOAD IS", response.data);

      })
      .catch(error => {
        console.error('Error fetching Projects data:', error);
      });
  }, [userID, myprojects]); //SetUserID is asynchronous, so it finishes running after useEffect is done and we don't display the users projects, by adding this as a dependency, when userID has its desired value, useffect reloads and we can see the projects
 

  // Function to handle button click and store the ID of the selected project
//   const handleSelectProject = (project) => {

    
//     //setEditProjectMenu(true);
//     //setNewProjectMenu(false);
    
//     setSelectedProject(project);


     
// };
const handleSelectProject = (project) => {


    const editProjectTemp=!editProjectMenu;
    
    setEditProjectMenu(editProjectTemp);
    setNewProjectMenu(false);
    
    setSelectedProject(project);
     
};
//Function to create a new project
const handleSelectNewProject = () =>{
    console.log("create neew project")
    setEditProjectMenu(false);

    const newmode=newProjectMenu;
    setNewProjectMenu(!newmode);

}

//Function to switch form view to edit mode
const handleEditProjectClick = () =>{
    const mode=editMode;
    setEditMode(!mode);

    const formValues={
        id: selectedProject.id,
        projectUserID: selectedProject.projectUserID,
        projectName:selectedProject.projectName,
        description: selectedProject.description,
    
       numberofalternatives:selectedProject.numberofalternatives,
        midpoints:selectedProject.midpoints,
       
        }
        setEditProjectData(formValues);
}


const handleEditProjectData = (event)=>{
    
   
    const fieldName=event.target.getAttribute("name");
   
    const fieldValue=event.target.value;
   
    const newFormData={...editProjectData}
    newFormData[fieldName]=fieldValue;
    setEditProjectData(newFormData);
    

    setSelectedProject(newFormData);
    
}

const handleNewProjectData = (event)=>{
    
   
    const fieldName=event.target.getAttribute("name");
   
    const fieldValue=event.target.value;
   
    const newFormData={...newProject} //addProjectData
    newFormData[fieldName]=fieldValue;

    //setAddProjectData(newFormData);
    
    setNewProject(newFormData);
    
}

const handleSaveEditProject=()=>{

    const newMyprojects=[...myprojects];
        const index=myprojects.findIndex((project)=>project.id===selectedProject.id)
        newMyprojects[index]=editProjectData;

       
      
    setMyprojects(newMyprojects);



    const mode=editMode;
    setEditMode(!mode);

    setSelectedProject(editProjectData);


    //Code to send the newMyProjects data to DB backend

    const id=editProjectData.id;
    const projectName=editProjectData.projectName;
    const projectUserID=editProjectData.projectUserID;
    const description=editProjectData.description;
    const numberofalternatives=editProjectData.numberofalternatives;
    const midpoints=editProjectData.midpoints;

    Axios.post("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/editproject", {id:id, projectName:projectName, projectUserID: projectUserID, description:description, numberofalternatives:numberofalternatives,midpoints:midpoints}).then(()=>alert("success"));

 


};

const handleDeleteProject=()=>{

    const newMyprojects=[...myprojects];
        const index=myprojects.findIndex((project)=>project.id===selectedProject.id)
    
        newMyprojects.splice(index, 1);

    setMyprojects(newMyprojects);

    setEditMode(false);

    setSelectedProject(0);
    
    setEditProjectMenu(false);


    //Code to remove the project from the database

    const id=myprojects[index].id; //Index retreived is not the same as id, we need to retrive the id to find the right element in the db.

    
    console.log(id);

    console.log(newMyprojects);


    Axios.delete("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/deleteproject", {
            data: {id: id} // Specify the id of the line to delete
        }).then(() => {
            alert("Line deleted successfully");
        }).catch(error => {
            console.error("Error deleting line:", error);
        });


};


const handleCreateProjectClick=()=>{

    const newMyprojects=[...myprojects];
       
        
        newMyprojects.push(newProject);
       
    
    setMyprojects(newMyprojects);
    setNewProject({
        id:null,
        
        projectName:"",
        description:"",
       numberofalternatives:0,
        midpoints:"",
        projectUserID:userID, // IMPORTANT TO DEFINE THIS HERE BECAUSE IT WONT BE DEFINED IN THE ENTRIES FOR NEW PROJECTS
    });

    setNewProjectMenu(false);

    


    //Code to add the new project to the DB backend

    //const id=newProjectData.id;
    const projectName= newProject.projectName;
    const projectUserID=userID; // This is fixed and does not depend on the inputs of the user
    const description=newProject.description;
    const numberofalternatives=newProject.numberofalternatives;
    const midpoints=newProject.midpoints;

    Axios.post("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/insertproject", { projectName:projectName, projectUserID: projectUserID, description:description, numberofalternatives:numberofalternatives,midpoints:midpoints}).then(()=>alert("success"));

 




};

const handleDuplicateProject=()=>{


    // const duplicateProject=selectedProject;
    // duplicateProject.projectName=selectedProject.projectName+"Copy";
    // duplicateProject.id=null;


    // const newMyprojects=[...myprojects];
       
        
    //     newMyprojects.push(duplicateProject);
       
    
    // setMyprojects(newMyprojects);
    // setNewProject(duplicateProject);

    // setNewProjectMenu(false);


    // //Code to add the new project to the DB backend

    //const id=newProjectData.id;
    // const projectName= duplicateProject.projectName;
    // const projectUserID=duplicateProject.projectUserID;
    // const description=duplicateProject.description;
    // const numberofalternatives=duplicateProject.numberofalternatives;
    // const midpoints=duplicateProject.midpoints;


    //NOW WE NEED TO create a duplicate project function backend, which is the same as INSERTPROJECT but that also duplicates the inputs table

    const originalID=selectedProject.id;
    const newProjectName=selectedProject.projectName+" Copy";


    Axios.post("https://greenfocus-impactlab-22e1f7352c18.herokuapp.com/api/duplicateproject", { originalID:originalID, newProjectName:newProjectName}).then(()=>alert("success"));

    setMyprojects([]); // WE change myprojects value, so that useffect is trggered and we render the updated list of project sent from backend
    setEditProjectMenu(false);

 



};

const handleCancelEditClick=()=>{
 const mode=editMode;
    setEditMode(!mode);


    //To retrieve the original values before editing, we go pick them from myprojects object, and reset the selected object to the original object so that it appears as before
        const index=myprojects.findIndex((project)=>project.id===selectedProject.id)
        
        setSelectedProject(myprojects[index]);
};

const handleCancelNewClick=()=>{
  
       

       const emptyProject={
        id:null,
        
        projectName:"",
        description:"",
       numberofalternatives:2,
        midpoints:"",
        projectUserID:userID, // IMPORTANT TO DEFINE THIS HERE BECAUSE IT WONT BE DEFINED IN THE ENTRIES FOR NEW PROJECTS
    }
           setNewProject(emptyProject);

           setNewProjectMenu(false);
   };
   

   

 const [clientSecretCode,setclientSecretCode]= useState(null);

   const handleClientSecretCode=(event)=>{

    const tempclientSecretCode=event.target.value;
  
        setclientSecretCode(tempclientSecretCode);
};

const clickClientSecretCode=()=>{

    setUserID(clientSecretCode);
    console.log("SETUSERID TO CLIENTSECRET CODE =", clientSecretCode)

    
};

const clickClientLogout=()=>{

    setUserID(null);
    setMyprojects([]);

    
};


   

    return (
        

     <div className="app-container">

        <div>{!(myprojects.length === 0) ? (<div>


        <HeaderLCAPage selectedProject={selectedProject} />

        <div className="container">

       <h1>My Projects</h1>

       <button onClick={() => clickClientLogout()}>Log Out</button>
       
       <h3> Selected Project: <em>{selectedProject ? selectedProject.projectName : 'None - Please select or create a project below'}</em></h3>
       
      

      
      <div className="projectBox">
            
            
            {myprojects.map((project,i)=>(

                //  <div key={i} className="project">

<button className="project" onClick={() => handleSelectProject(project)}>

                    <h3>{project.projectName}</h3>
                    <p>{project.description}</p>
                    </button>
                //    {/* <button onClick={() => handleSelectProject(project)}> Select </button>*/}
                //     {/*  <div><Link to="/lcapage/inputs"> <button onClick={() => handleDetailProject(project)}>Select</button></Link></div>*/}
                //  </div>
                
                
            )
           
            )}
        
            {/* <div className="project new"> */}
            <button className="project new" onClick={() => handleSelectNewProject()}>
                    <h3>Create New Project</h3>
                    <p>Click here to create a new project</p>
                    {/* <button onClick={() => handleSelectNewProject()}> Create </button> */}
                    </button>
                {/* </div> */}

            </div>



            {editProjectMenu && (
            <table>
           
            <tbody>
                <tr><td> Name </td></tr>
                <tr><td>{!editMode?selectedProject.projectName:(<input  value={selectedProject.projectName|| ''} // Ensure the value is defined; if undefined, use an empty string
                type="text"
        name="projectName"
        required="required"
        placeholder={selectedProject.projectName|| ''} // Ensure the value is defined; if undefined, use an empty string
        onChange={handleEditProjectData}/> )}</td></tr>
             
                <tr><td>Project Description</td></tr>
                <tr><td>{!editMode?selectedProject.description:(<input value={selectedProject.description|| ''} // Ensure the value is defined; if undefined, use an empty string type="text"
        name="description"
        required="required"
        placeholder={selectedProject.description|| ''} // Ensure the value is defined; if undefined, use an empty string
        onChange={handleEditProjectData}/> )}</td></tr>
              
                <tr><td>Nr of Alternatives/Scenarios </td></tr>
                <tr><td>{!editMode?selectedProject.numberofalternatives:(<input value={selectedProject.numberofalternatives|| ''} // Ensure the value is defined; if undefined, use an empty string type="text"
        name="numberofalternatives"
        required="required"
        placeholder={selectedProject.numberofalternatives|| ''} // Ensure the value is defined; if undefined, use an empty string
        onChange={handleEditProjectData}/> )}</td></tr>
               
                <tr><td>Output/Midpoints</td></tr>
                <tr><td>{!editMode?selectedProject.midpoints:(<input value={selectedProject.midpoints|| ''} // Ensure the value is defined; if undefined, use an empty string type="text"
        name="midpoints"
        required="required"
        placeholder={selectedProject.midpoints|| ''} // Ensure the value is defined; if undefined, use an empty string
        onChange={handleEditProjectData}/> )}</td></tr>

                <tr><td>Actions</td></tr>
                <tr><td>{!editMode?(<div><button onClick={() => handleEditProjectClick()}>Edit Project</button><button onClick={() =>handleDeleteProject()}>Delete Project</button><button onClick={() =>handleDuplicateProject()}>Duplicate</button></div>):<div><button onClick={() => handleSaveEditProject()}>Save</button><button onClick={() => handleCancelEditClick()}>Cancel</button></div>}
                </td></tr>
              
                
            </tbody>
        </table>

)}


{newProjectMenu && (
            <table>
           
            <tbody>
                <tr><td> Name </td></tr>
                <tr><td><input  value={newProject.projectName || ''} // Ensure the value is defined; if undefined, use an empty string
                type="text"
        name="projectName"
        required="required"
        placeholder={newProject.projectName|| ''} // Ensure the value is defined; if undefined, use an empty string
        onChange={handleNewProjectData}/></td></tr>
             
                <tr><td>Project Description</td></tr>
                <tr><td><input value={newProject.description|| ''} // Ensure the value is defined; if undefined, use an empty string type="text"
        name="description"
        required="required"
        placeholder={newProject.description|| ''} // Ensure the value is defined; if undefined, use an empty string
        onChange={handleNewProjectData}/></td></tr>
              
                <tr><td>Nr of Alternatives/Scenarios </td></tr>
                <tr><td><input value={newProject.numberofalternatives|| ''} // Ensure the value is defined; if undefined, use an empty string type="text"
        name="numberofalternatives"
        required="required"
        placeholder={newProject.numberofalternatives|| ''} // Ensure the value is defined; if undefined, use an empty string
        onChange={handleNewProjectData}/></td></tr>
               
                <tr><td>Output/Midpoints</td></tr>
                <tr><td><input value={newProject.midpoints || ''} // Ensure the value is defined; if undefined, use an empty string type="text"
        name="midpoints"
        required="required"
        placeholder={newProject.midpoints|| ''} // Ensure the value is defined; if undefined, use an empty string
        onChange={handleNewProjectData}/></td></tr>

                <tr><td>Actions</td></tr>
                <tr><td><div><button onClick={() => handleCreateProjectClick()}>Create Preject</button><button onClick={() => handleCancelNewClick()}>Cancel</button></div>
                </td></tr>
              
                
            </tbody>
        </table>

)}





            

        

        
        
      

        


</div>
</div>):
<div className="app-container loginpage">
<div className="loginbox">
<h1>Access Code</h1>
<p> Please enter your Client Secret Access Code</p>
<input style={{marginBottom:"10px"}} value={clientSecretCode}
        name="code"
        required="required"
        placeholder="Enter Code" // Ensure the value is defined; if undefined, use an empty string
        onChange={handleClientSecretCode}/>
        <button onClick={() => clickClientSecretCode()}>Enter Code</button>

</div>
</div>




}</div>



</div>
        
    );
  }

export default Project;
